import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CarOutlined,
  CloudServerOutlined,
  DesktopOutlined,
  DollarOutlined,
  GiftOutlined,
  GoldOutlined,
  HomeOutlined,
  LogoutOutlined,
  PictureOutlined,
  SelectOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  BarcodeOutlined,
  BuildOutlined,
  SlidersOutlined,
  OneToOneOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IoCashOutline } from "react-icons/io5";
import SubMenu from "antd/es/menu/SubMenu";
import { logoutAdmin } from "../actions/AuthAction";
import { isPermitted } from "../utils/PermissionManager";
import SideMenu from "./SideMenu";
//   import { sidebarItems } from "../utils/Sidebars";
const { Sider } = Layout;

export default function Sidebar(props) {
  // console.log('roleNow', roleNow)
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const location = useLocation();
  const path = location.pathname;
  const { show, setshow } = props.state;
  const roles = useSelector((state) => state.auth.profile?.roles);
  let dev_server = window.location.hostname === "localhost" ? true : false;
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  // console.log("update", update);
  // adminRole

  useEffect(() => {
    // if (screens.xs) {
    //   setshow(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, screens]);

  // console.log('roles.includes(roleNow)', sidebarItems.filter((item) => ))

  const menuStyle = {
    fontSize: "16px",
    width: "18px",
  };

  return (
    <>
      {location.pathname !== "/login" &&
        !location.pathname.includes("public") && (
          <Sider
            trigger={null}
            breakpoint="xs"
            onBreakpoint={(broken) => {
              setshow(broken);
            }}
            width="240px"
            style={{
              overflow: "auto",
              position: "fixed",
              zIndex: 1,
              left: 0,
              bottom: 0,
              top: 0,
              paddingTop: "0.5rem",
              backgroundColor: "white",
              fontWeight: "bold",
              width: "240px",
            }}
            collapsedWidth="0"
            collapsed={show}
          >
            <a className="flex align-center justify-center p1" href="/">
              <img
                style={{ width: "160px" }}
                src={require("../assets/logo.svg").default}
                alt=""
              />
            </a>
            <SideMenu />
          </Sider>
        )}
    </>
  );
}
